@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

*{
  margin: 0;
  padding: 0;
  font-family: 'Mulish', sans-serif;
}
body{
  position: relative;
}

html::-webkit-scrollbar{
  display: none;
}
.navbar{
  background-color: #fff !important;
}

.navbar-brand{
  font-size: 1.9rem !important;
  color: #565387 !important;
}
.navbar a{
  font-size: 1.3rem;
  text-transform: capitalize;
}
.menu_active{
  font-weight: bold;
  border: 1px solid #565387;
}

.navbar a:hover{
  color: #3498db !important;
}
.header{
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
}
.flex{
  display: flex;
  justify-content: center;
  
  flex-direction: column;
}
footer{
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}


@media (max-width:991px) {
 
  .navbar-brand{
    font-size: 1.5rem !important;
  }
  .menu_active{
    font-weight:bold ;
    border-bottom: none;
  }
  .header-img{
    display: flex;
    justify-content: center;
  }
  .header-img img{
    width: 500px;
  }
  #header{
    height: 100vh;
  }
  footer{
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
  }
 
}
@media (max-width:400px) {
  .navbar-brand{
  font-size: 1rem !important;
  }
 
}
@media (max-width:320px) {
  .header-img {
    display: flex;
    justify-content: center;
  }
  .header-img img{
    width: 140px;
  }
  footer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
/* main landing page */
#header{
  width: 100%;
  height: 100vh;
  margin-top: 50px;
}
#header h1{
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
}
#header .brand-name{
    color: #3498db !important;
}
#header h2{
  color: #484848;
  font-size: 24px;
}
#header .btn-get-started{
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #3498db;
  border: 2px solid #3498db;
  text-decoration: none;
}
#header .btn-get-started:hover{
  background:#3498db ;
  color: #fff;
}
.header-img{
  text-align: right;
  margin-top: 20px;
}
/* animation */
#header .animated{
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@keyframes up-down {
  0%{
   transform: translateY(10px); 
  }
  100%{
    transform: translateY(-10px);
  }
  
}
@-webkit-keyframes  up-down {
  0%{
   transform: translateY(10px); 
  }
  100%{
    transform: translateY(-10px);
  }
  
}
/* services */
